// InstallationPage.js
import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase'; // Adjust the import path as necessary
import InstallationInstructions from '../components/install/install.js';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const InstallationPage = () => {
  const [wordPressApiKey, setWordPressApiKey] = useState('');

  useEffect(() => {
    const generateApiKey = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const userRef = doc(firestore, 'customers', userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists() && userDoc.data().wordPressAPI) {
          setWordPressApiKey(userDoc.data().wordPressAPI);
        } else {
          const newApiKey = 'wp_' + Math.random().toString(36).substr(2, 9);
          await setDoc(userRef, { wordPressAPI: newApiKey }, { merge: true });
          setWordPressApiKey(newApiKey);
        }
      }
    };

    generateApiKey();
  }, []);

  return (
    <div className="installation-page">
      <InstallationInstructions wordPressApiKey={wordPressApiKey} />
    </div>
  );
};

export default InstallationPage;
