import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import DesignComponent from './DesignComponent'; 
import StandaloneAudioPlayer from './StandaloneAudioPlayer';
import Header from './Header'; 
import './DemoPage.css'; 

const DemoPage = () => {
  const [text, setText] = useState('');
  const [embedCode, setEmbedCode] = useState('');
  const [audioGenerated, setAudioGenerated] = useState(false); // New state to track if audio is generated
  const [audioKey, setAudioKey] = useState(0);
  const [audioUrl, setAudioUrl] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('Alloy');
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [docId, setDocId] = useState('');
  const [stripePriceId, setStripePriceId] = useState('');
  const [charLimit, setCharLimit] = useState(500);
  const [customStyles, setCustomStyles] = useState({
    container: {},
    player: {},
    showCompanyTag: true,
  });

  const defaultStyles = {
    container: {},
    player: {},
    showCompanyTag: true,
  };
  

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= charLimit) {
      setText(newText);
    }
  };

  const handlePaymentRequirement = (requiresPayment, priceId) => {
    setPaymentRequired(requiresPayment);
    setStripePriceId(priceId);
  };

  const handleGenerateAudio = async () => {
    if (!text.trim()) { // Check if the text is empty or just spaces
      alert("Please enter some text before generating audio.");
      return; // Exit the function to prevent further execution
    }
    try {
      const response = await fetch('https://us-central1-butter-reader.cloudfunctions.net/synthesizeDemoSpeech', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text, voice: selectedVoice.toLowerCase() })
      });

      if (response.ok) {
        const data = await response.json();
        setAudioUrl(data.url);
        setAudioKey(prevKey => prevKey + 1);

        const docRef = await addDoc(collection(firestore, "demo"), {
          audioUrl: data.url,
          customStyles,
          showCompanyTag: customStyles.showCompanyTag,
        });

        setDocId(docRef.id); // Save the document ID for later
        setAudioGenerated(true);

        // Only set the embed code here if no payment is required
        if (!paymentRequired) {
          generateEmbedCode(data.url, docRef.id);
        }
      } else {
        console.error("Failed to generate audio: ", await response.text());
      }
    } catch (error) {
      console.error("Error generating audio or saving configuration: ", error);
    }
  };

  const handlePayment = async () => {
    // Assuming you have a priceId and other necessary details
    const docRef = await addDoc(collection(firestore, "demo"), {
      audioUrl,
      customStyles,
      showCompanyTag: customStyles.showCompanyTag,
      // You might want to include other relevant information
    });
  
    const response = await fetch('https://us-central1-butter-reader.cloudfunctions.net/createCheckoutSession', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        stripePriceId,
        docId: docRef.id,
      }),
    });
  
    if (response.ok) {
      const data = await response.json();
      // Redirect user to Stripe Checkout Page
      window.location.href = data.url;
    } else {
      console.error("Failed to initiate payment: ", await response.text());
    }
  };

  const handleSignificantChange = () => {
    setAudioGenerated(false); // Reset the flag indicating that the audio has been generated
    setEmbedCode(''); // Clear the embed code until new audio is generated
    // Optionally, clear other related states as needed
  };
  

  const generateEmbedCode = (audioSrc, docId) => {
    const newEmbedCode = `<div id="demo-widget-container" data-config-id="${docId}"></div>
<script src="https://app.butterreader.blog//widget/DemoWidget.bundle.js"></script>`;
    setEmbedCode(newEmbedCode);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentSuccess = params.get('paymentSuccess');
    const docIdFromQuery = params.get('docId');

    if (paymentSuccess === 'true' && docIdFromQuery) {
      setPaymentSuccessful(true);
      fetchDocumentAndGenerateEmbedCode(docIdFromQuery);
    }
  }, []);

  const handleTryAgain = () => {
    window.location.href = '/demo'; 
  };
  
  const fetchDocumentAndGenerateEmbedCode = async (docId) => {
    const docRef = doc(firestore, 'demo', docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setAudioUrl(data.audioUrl); // Set audio URL from document
      setCustomStyles(data.customStyles || defaultStyles); // Update custom styles or fallback to default
      generateEmbedCode(data.audioUrl, docId); // Regenerate embed code with the document's audio URL
    } else {
      console.error("Document not found");
    }
  };

  const handleDesignChange = (newStyles) => {
    if (audioGenerated) {
      setAudioGenerated(false);
      setEmbedCode('');
      // Optionally, clear audioUrl if you want the user to regenerate audio for the new design
      setAudioUrl('');
    }
    setCustomStyles(prevStyles => ({ ...prevStyles, ...newStyles }));
  };

  return (
    <div className="demo-full-page">
      <Header />
      <div className="demo-page">
        <div className="left-panel">
          {!paymentSuccessful ? (
            <>
              <h1>Generate Audio Widget For Your Site</h1>
              <p>Input the text you wish to convert to audio and simply paste the embed code within your website. If you love it and would like to add it to your blog then visit <a href="/" style={{color: '#ff7900'}}>here</a>.</p>
              <div className="textarea-container">
                <textarea 
                  value={text} 
                  onChange={handleTextChange} 
                  maxLength={charLimit} 
                  placeholder="Enter text here..." 
                />
                <div className="char-count">
                  {text.length}/{charLimit}
                </div>
              </div>
              {text.trim().length > 0 && (
                <button className='copy-button' onClick={handleGenerateAudio} disabled={!selectedVoice}>Generate</button>
              )}
              {audioGenerated && !paymentRequired && (
                <div className="embed-code-div">
                  <h3>Embed Code:</h3>
                  <textarea value={embedCode} readOnly />
                  <button className='copy-button' onClick={() => navigator.clipboard.writeText(embedCode)}>Copy</button>
                </div>
              )}
              {audioGenerated && paymentRequired && (
                <div className="embed-code-demo">
                  <div className="blurry-embed-code">
                    <textarea value={embedCode} readOnly />
                  </div>
                  <button onClick={handlePayment} className="pay-button">Pay to Unlock Embed Code</button>
                </div>
              )}
            </>
          ) : (
            <>
              <h3>Embed Code:</h3>
              <textarea value={embedCode} readOnly />
              <button className='copy-button' onClick={() => navigator.clipboard.writeText(embedCode)}>Copy</button>
              <button className='try-button' onClick={handleTryAgain} style={{ marginTop: "20px" }}>Try Again</button>
            </>
          )}
        </div>
        <div className="right-side-demo">
          <StandaloneAudioPlayer key={audioKey} audioSrc={audioUrl} customStyles={customStyles} showCompanyTag={customStyles.showCompanyTag} />
          {!paymentSuccessful && (
            <DesignComponent
              onDesignChange={handleDesignChange}
              onVoiceChange={setSelectedVoice} // Ensure this is correctly passed
              onPaymentRequirement={handlePaymentRequirement}
              onSignificantChange={handleSignificantChange}
            />          
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoPage;