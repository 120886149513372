import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase'; // Adjust the import path as necessary
import './install.css'; // Ensure the CSS path is correct
import Knife from '../../assets/icons/knife.svg';
import Wordpress from '../../assets/icons/wordpress.svg';
import Webflow from '../../assets/icons/webflow.svg';
import Shopify from '../../assets/icons/shopify.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';

const InstallationInstructions = ({ wordPressApiKey }) => {
  const [activePlatform, setActivePlatform] = useState('manual');
  const [embedCode, setEmbedCode] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const scriptTag = `<div id="audio-player-widget-container"></div>
<script src="https://app.butterreader.blog//widget/AudioPlayerWidget.bundle.js"></script>
<script>
  window.initAudioPlayerWidget('audio-player-widget-container', '${userId}');
</script>
`;
      setEmbedCode(scriptTag);
    }
  }, []);

  const handleCopy = () => {
    if (activePlatform === 'wordpress') {
      return; // Do nothing for WordPress
    }
    navigator.clipboard.writeText(embedCode).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const platforms = {
    manual: {
      title: "Manual Installation",
      icon: Knife,
      instructions: [
        "Copy the widget code snippet.",
        "Access your website's HTML.",
        "Make sure your Blog title is H1, and your blog body is under classname: 'article, .post, .entry-content, or .rich-text-block.w-richtext' for best results,",
        "Paste the snippet immediately after your main blog image, before the start of the blog content."
      ]
    },
    wordpress: {
      title: "WordPress",
      icon: Wordpress,
      instructions: [
        "Plugin coming soon..."
      ]
    },
    webflow: {
      title: "Webflow",
      icon: Webflow,
      instructions: [
        "Open your Webflow dashboard and go to your project.",
        "Navigate to CMS collections page and select the Blogs Template.",
        "Add an 'Embed' element at the desired location (preferably right after the main blog image).",
        "Make sure your Blog title is H1, and your blog body is under classname: 'article, .post, .entry-content, or .rich-text-block.w-richtext' for best results,",
        "Paste the widget code snippet into the HTML field of the 'Embed' element.",
        "For detailed instructions, visit (https://university.webflow.com/lesson/custom-code-embed?topics=elements)."
      ]
    },
    shopify: {
      title: "Shopify",
      icon: Shopify,
      instructions: [
        "Log in to your Shopify admin panel.",
        "Go to 'Online Store' and then 'Themes'.",
        "Find the theme you want to edit and click 'Edit Code'.",
        "Make sure your Blog title is H1, and your blog body is under classname: 'article, .post, .entry-content, or .rich-text-block.w-richtext' for best results,",
        "Copy your embed code and paste it in the HTML code AFTER your H1 Title and image",
        "For more details, refer to (https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/edit-theme-code)."
      ]
    },
  };

  // Function to render instructions for the currently selected platform
  const renderInstructions = () => {
    return platforms[activePlatform].instructions.map((step, index) => (
      <li key={index}>{step}</li>
    ));
  };

  return (
    <div className="design-install-box">
      <div className="platform-tabs">
        {Object.keys(platforms).map((platform) => (
          <button
            key={platform}
            className={`tab ${activePlatform === platform ? 'active' : ''}`}
            onClick={() => setActivePlatform(platform)}
          >
            <img src={platforms[platform].icon} alt={`${platforms[platform].title} icon`} className="platform-icon" />
            <span className="install-title" >{platforms[platform].title}</span> {/* Wrap title in span for styling if needed */}
          </button>
        ))}
      </div>
      <div className="embed-code-section">
        <h3 className='embed-label'>
          {activePlatform === 'wordpress' ? 'WordPress Plugin' : 'Embed your widget'}
        </h3>
        {activePlatform === 'wordpress' ? (
          <div className="coming-soon-message">Plugin coming soon...</div>
        ) : (
          <div className='widget-copy-box' onClick={handleCopy}>
            <pre className="embed-box">{embedCode}</pre>
            <CopyIcon className="copy-icon" />
          </div>
        )}
        {copied && <div className="copy-notification">Copied!</div>}
      </div>
      <div className="instruction-steps">
        <h2>{platforms[activePlatform].title}</h2>
        <ol>
          {renderInstructions()}
        </ol>
      </div>
    </div>
  );
};

export default InstallationInstructions;
