import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../../firebase'; // Make sure this path is correct
import { collection, query, where, orderBy, getDocs, limit } from 'firebase/firestore';
import GreenIcon from '../../assets/icons/green.svg';
import RedIcon from '../../assets/icons/red.svg';
import './Stats.css';

const Leaderboard = () => {
  const [activeTab, setActiveTab] = useState('myAudio');
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    const fetchLeaderboardData = async (uid) => {
      try {
        let q;
        if (activeTab === 'myAudio') {
          q = query(
            collection(firestore, 'playEvents'),
            where('userId', '==', uid),
            where('hostUrl', '!=', null),
            orderBy('hostUrl'),
            orderBy('timestamp', 'desc'),
            limit(5)
          );
        } else {
          q = query(
            collection(firestore, 'playEvents'),
            where('hostUrl', '!=', null),
            orderBy('hostUrl'),
            orderBy('timestamp', 'desc'),
            limit(5)
          );
        }

        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc, index) => {
          const eventData = doc.data();
          return {
            rank: index + 1,
            audioSrc: eventData.audioSrc,
            hostUrl: eventData.hostUrl || 'Unknown URL',
            changeIcon: index === 0 ? GreenIcon : (index === querySnapshot.docs.length - 1 ? RedIcon : null)
          };
        });
        setLeaderboardData(data);
      } catch (error) {
        setError('Failed to load leaderboard data');
        console.error('Fetch error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user || activeTab === 'worldwide') {
        fetchLeaderboardData(user ? user.uid : null);
      } else {
        setError('User is not authenticated');
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [activeTab]);

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-content">
        <h3 className="leaderboard-title">Popular Pages</h3>
        {isLoading ? (
          <p className='inside-content'>Loading...</p>
        ) : error ? (
          <p className='inside-content'>{error}</p>
        ) : leaderboardData.length > 0 ? (
          <ul className="leaderboard-list">
            {leaderboardData.map((item, index) => (
              <li key={index} className="leaderboard-item">
                <div className="rank-circle">{item.rank}</div>
                <span className="name">{new URL(item.hostUrl).hostname}</span>
                {item.changeIcon && (
                  <span className="icon-change">
                    <img src={item.changeIcon} alt="Rank change" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className='inside-content'>No data available</p>
        )}
      </div>
      <div className="leaderboard-footer">
        <button
          onClick={() => setActiveTab('myAudio')}
          className={`tab-button ${activeTab === 'myAudio' ? 'active' : ''}`}
        >
          My Audio
        </button>
        <button
          onClick={() => setActiveTab('worldwide')}
          className={`tab-button ${activeTab === 'worldwide' ? 'active' : ''}`}
        >
          Worldwide
        </button>
      </div>
    </div>
  );
};

export default Leaderboard;
